@import 'styles/layers.scss';

@layer component {
  .mb {
    margin-bottom: 16px;
  }

  .radio-container {
    &.column {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &.row {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .mt {
    margin-top: 4px;
  }

  .description {
    font-size: 12px;
    opacity: 0.3;
    margin-bottom: 8px;
  }

  .ext {
    margin-left: 6px;
    color: var(--grey7);
  }
}